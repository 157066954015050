import { Box, Button, Center, Divider, Flex, Heading, Image, Stack, Text, Wrap, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";

import { useState } from "react";

import { IDocument } from "@config/types";
import { convertEpochToDate, objToQuery, otherDocumentFilter, showErrorToast, showSuccessToast } from "@utils";
import UploadDocumentButton from "@components/molecules/UploadDocumentButton";
import { useRedStore } from "@store/store";
import DocumentCard from "@components/molecules/DocumentCard";
import { useAssetRemove } from "@hooks/api/useCaseApi";
import { queryClient } from "scripts/reactQuery";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import AssetRender from "@components/molecules/AssetRender";
import { MAX_DOCUMENT_COUNT } from "@config/constant";
import { useNavigate } from "react-router-dom";
import { caseHistoryClickOnScheduledNowButton } from "@config/event";

export default function CaseHistoryAction({ isDisabled }: { isDisabled: boolean }) {
  const darkSlate = useColorModeValue("white", "slate.800");
  const order = useRedStore(state => state.order);
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const navigate = useNavigate();

  const params = {
    onSuccessNavigate: `/case-history/${order?.orderId}/case-overview`,
  };

  const handleOnPayClick = () => {
    caseHistoryClickOnScheduledNowButton({
      orderId: order?.orderId,
      caseStatus: order?.orderStatus,
    });
    navigate(`/case/${order?.orderId}/ready-to-dispatch?${objToQuery(params)}`);
  };

  const isScheduledCasePendingDispatch = order.orderStatus === "draft" && order.bookingType === "BOOKING" && order.dispatchType === "SCHEDULED";
  
  return isScheduledCasePendingDispatch ? (
    <Wrap w={"full"} bg={darkSlate} px={4} py={4} mt={6} spacing={10} borderRadius={"md"}>
      {" "}
      <Center w={"full"} flexDirection={"column"}>
        <Image src="/assets/ambulances/schedule.png" w={20} my={5} />
        <Text my={5} mt={2} textAlign={"center"} fontSize={"sm"}>
          The advance has been collected, but you have not scheduled the case yet. Please schedule it.
        </Text>
        <Button
          px={8}
          bg={buttonColorValue}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={handleOnPayClick}
          w={"full"}
          // isLoading={smsStatus.isLoading}
          // isDisabled={phoneNo?.length !== 10}
          fontWeight={"semibold"}
        >
          Schedule Now
        </Button>
      </Center>
    </Wrap>
  ) : (
    <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
      {" "}
      <Center w={"full"} flexDirection={"column"}>
        <Image src="/assets/ambulances/ambulance.png" w={20} my={5} />
        <Text my={5} mt={2} textAlign={"center"} fontSize={"sm"}>
          {isDisabled ? "To assign vehicle, Please remove the case from On Hold" : "Ambulance assignment has failed or is pending. Please assign a vehicle."}
        </Text>
        <Button
          px={8}
          bg={buttonColorValue}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={handleOnPayClick}
          w={"full"}
          // isLoading={smsStatus.isLoading}
          // isDisabled={phoneNo?.length !== 10}
          isDisabled={isDisabled}
          fontWeight={"semibold"}
        >
          Assign Vehicle
        </Button>
      </Center>
    </Wrap>
  );
}
