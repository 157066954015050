import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ActionButton from "@components/atom/ActionButton";
import TextRenderWithHeading from "@components/atom/TextRenderWithHeading";
import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import CancelOrder from "@components/molecules/CancelOrder";
import CaseHistoryAction from "@components/molecules/CaseHistoryAction";
import { DatePickerHandler } from "@components/molecules/DispatchType";
import DocumentUploadLink from "@components/molecules/DocumentUploadLink";
import Feedback from "@components/molecules/Feedback";
import ForceDispatch from "@components/molecules/ForceDispatch";
import IpPatientNumber from "@components/molecules/IpPatientNumber";
import NotAllowToDispatch from "@components/molecules/NotAllowToDispatch";
import OnHold from "@components/molecules/OnHold";
import PatientSection from "@components/molecules/PatientSection";
import TransactionComponent from "@components/molecules/TransactionComponent";
import UploadBTHDocuments from "@components/molecules/UploadBTHDocuments";
import UploadDocuments from "@components/molecules/UploadDocuments";
import ViewAuditData from "@components/molecules/ViewAuditData";
import { CaseStatusRender } from "@components/table/CaseListingTableComponent";
import DatePicker from "react-datepicker";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { ambulanceMapper, ENABLED_AUDIT_SERVICE_TYPE, HM_CAN_DISPATCH_SERVICE_TYPE, SERVICE_TYPE_MAP, X_DISTANCE } from "@config/constant";
import { pageView } from "@config/event";
import { usePageTitle, useTitle } from "@hooks/action/useUtils";
import { useAuditData, useGetCase } from "@hooks/api/useCaseApi";
import { usePaymentInfo } from "@hooks/api/usePayment";
import { usePaymentData } from "@hooks/api/useWalletApi";
import { Icon } from "@iconify/react";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import { TextMaker, convertDistance, convertPrice, convertToCurrency, getAddonsName, isInvalidString, maskString, objToQuery, objectToQueryString, removeUnderscore } from "@utils";
import dayjs from "dayjs";
import { getProperty } from "dot-prop";
import { redEvent } from "lib/red-hooks";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { updateCaseScheduledDTM } from "api/service/case";
import { Loader } from "@pages/Navigation";

export default function CaseHistory() {
  useTitle("Case History");
  const [is_more_then_x_km, is_hm_can_assign] = useRedStore(state => [state.localOrderChanges?._is_more_then_x_km, state.localOrderChanges?._hm_can_assign]);
  const _updateCurrentPageTitle = useRedStore(state => state._updateCurrentPageTitle);
  const orderData = useRedStore(state => state.order);
  useLayoutEffect(() => {
    _updateCurrentPageTitle("Case History");
    redEvent.pageView("Case History");
  }, []);

  const { id: orderId } = useParams();

  useEffect(() => {
    pageView("Case History", { orderId });
  }, []);

  const { data, ...rest }: any = useGetCase({ orderId, onSuccess: onCaseFetchSuccessfully });
  const { data: auditData, ...auditDataLoading }: any = useAuditData({ orderId: orderId || "" });
  const { data: paymentData, ...paymentResponse } = usePaymentData({ orderId, onSuccess: onCaseFetchSuccessfully });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editScheduledTime,setEditScheduledTime] = useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const [reasonForChangeScheduledTime,setReasonForChangeScheduledTime] = useState('')
  
  const { __updateScheduledDdtm } = useOrderAction();
  const userDateSelect:any = (date: Date) => {
    __updateScheduledDdtm(date);
  };

  const sourceMap = useRedStore(state => state.sourceMap);

  function onCaseFetchSuccessfully() {
    // isOpen && onClose();
  }

  const extractValue = (key: string, defaultVal?: string) => (data?.data && getProperty(data.data.data, key, defaultVal)) || "";
  const extractValueFromPayment = (key: string) => (data?.data && getProperty(paymentData?.data, key)) || "";

  const isTransitionFinish = ["fulfilled", "cancelled"].includes(extractValue("orderStatus"));
  const darkSlate = useColorModeValue("white", "slate.800");
  const subtype = extractValue("entityRequired[0].subtype");
  const pendingAmount = convertPrice(extractValueFromPayment("amountPending"));
  const isShowAuditPending =
    orderData.orderClassification !== "INBOUND" &&
    orderData?.orderId &&
    // !orderData?.billToClient &&
    !!ENABLED_AUDIT_SERVICE_TYPE?.includes(orderData?.serviceType) &&
    (!auditData || (!!auditData && auditData?.length <= 0)) &&
    !["draft", "fulfilled", "cancelled"].includes(orderData.orderStatus) &&
    !!subtype;

  const isCaseHistoryActionValid =
    orderData?.orderStatus === "unfulfilled" || (orderData?.orderStatus === "draft" && HM_CAN_DISPATCH_SERVICE_TYPE.includes(orderData.serviceType) && is_hm_can_assign && (!is_more_then_x_km || !!orderData.scheduledDdtm ));

  const notAllowedToDispatch = `HM Can't Dispatch ${removeUnderscore(
    orderData?.serviceType
  )} service type, only Center Dispatch team is allowed to dispatch this case, please connect with them to dispatch.`;

  // The case exceeds our dispatch range as the distance between the pickup and drop-off locations is 1543.74 km, surpassing the 100.00 km limit. Please contact the central dispatch team to manage the assignment.
  
  // The case exceeds our dispatch range as the distance between the pickup and drop-off locations is ${convertDistance(
    //   orderData?.distance
    // )} km, surpassing the ${convertDistance(X_DISTANCE)} km limit. Please contact the central dispatch team to manage the assignment.
    
    const xFeatureRestrictionMessage = `The case distance is ${convertDistance(orderData?.distance)} km and is beyond your dispatch limit of ${convertDistance(
      X_DISTANCE
    )} km. Please reach out to the central dispatch team for handling.`;
    
    const isShowActionSection = orderData.orderStatus === "unfulfilled" && !orderData.isCaseOnHold;
    
    const isThisCaseOnHold = orderData?.bookingType === "BOOKING" && ["draft", "unfulfilled"].includes(orderData.orderStatus) && !orderData.isCaseOnHold;
    const isForceDispatch = orderData.orderStatus === "scheduled" && HM_CAN_DISPATCH_SERVICE_TYPE.includes(orderData?.serviceType);
    const maxDate = new Date(extractValue("scheduledDdtm")); // Clone the selected date
    maxDate.setDate(maxDate.getDate() + 200);
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setMinutes(minDate.getMinutes() + 45);
    const [newScheduledTime, setNewScheduledTime] = useState(extractValue("scheduledDdtm"));
    const [newScheduledTimeEdited, setNewScheduledTimeEdited] = useState<boolean>(false);
    const isToday = newScheduledTime instanceof Date ? newScheduledTime.toDateString() : new Date(newScheduledTime).toDateString() === currentDate.toDateString();
    function updateScheduledDate(){
      setIsLoading(true)
      updateCaseScheduledDTM({scheduleAt: newScheduledTime, reason: reasonForChangeScheduledTime},extractValue("orderId"))
      .then(() =>{
        setEditScheduledTime(false); 
        setNewScheduledTimeEdited(false); 
        setNewScheduledTime(newScheduledTime)
        setReasonForChangeScheduledTime('')
      }).finally(() => setIsLoading(false))
    }
  return (
    <NetworkWrapper status={rest}>
      <Stack>
        <Flex>
          <Box flex="1">
            <Wrap w={"full"} justify={"space-between"} bg={darkSlate} align={"center"} px={4} py={4} borderRadius={"md"}>
              <SimpleGrid columns={2} w="full" gap={4}>
                <TextRenderWithHeading title="Order ID" description={extractValue("orderId")} />
                <WrapItem maxW={["full", 200]}>
                  <Box>
                    <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                      Case Status
                    </Text>
                    <CaseStatusRender status={extractValue("orderStatus")} />
                  </Box>
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  <Box>
                    <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                      Created At
                    </Text>
                    <Text fontSize={"sm"} fontWeight={"semibold"} color={"blackAlpha.800"}>
                      {dayjs.unix((extractValue("createdAt") || 1000) / 1000).format("DD MMM YYYY hh:mm A")}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  <TextRenderWithHeading title="Service Type" description={getProperty(SERVICE_TYPE_MAP, extractValue("serviceType"))?.label?.toString()} />
                </WrapItem>
              </SimpleGrid>
            </Wrap>
            {/* {isShowActionSection && (
              <Wrap bg={"purple.100"} mb={0} borderRadius={"md"} borderTopRadius={0} mt={-2} px={5} py={2}>
                <WrapItem w={"full"}>
                  <Wrap w={"full"}>
                    {orderData.orderStatus === "unfulfilled" && !orderData.isCaseOnHold && (
                      <WrapItem>
                        <OnHold isDisabled={false} />
                      </WrapItem>
                    )}
                    {orderData.orderStatus === "scheduled" && HM_CAN_DISPATCH_SERVICE_TYPE.includes(orderData?.serviceType) && (
                      <WrapItem w={"full"}>
                        <ForceDispatch isDisabled={is_more_then_x_km || !is_hm_can_assign} />
                      </WrapItem>
                    )}
                  </Wrap>
                </WrapItem>
              </Wrap>
            )} */}

            {(isForceDispatch || isThisCaseOnHold) && (
              <Flex bg={"purple.100"} mb={0} borderRadius={"md"} borderTopRadius={0} mt={-2} px={5} py={2}>
                {isThisCaseOnHold && (
                  <WrapItem w={"full"}>
                    <OnHold isDisabled={false} />
                  </WrapItem>
                )}
                {isForceDispatch && (
                  <WrapItem w={"full"}>
                    <ForceDispatch isDisabled={(is_more_then_x_km && !orderData.scheduledDdtm ) || !is_hm_can_assign} />
                  </WrapItem>
                )}
              </Flex>
            )}

            {orderData.isCaseOnHold && <CaseOnHold />}
            {(!is_more_then_x_km || !!orderData.scheduledDdtm ) && isCaseHistoryActionValid && <CaseHistoryAction isDisabled={orderData.isCaseOnHold} />}
            {!is_hm_can_assign && !is_more_then_x_km && <NotAllowToDispatch message={notAllowedToDispatch} query={removeUnderscore(orderData?.serviceType)} />}
            {pendingAmount && orderData?.orderId && !orderData.billToClient ? <PendingPaymentCard amount={pendingAmount} orderId={orderData?.orderId} /> : <></>}

            {is_more_then_x_km && !orderData.scheduledDdtm ? <NotAllowToDispatch message={xFeatureRestrictionMessage} query={convertDistance(X_DISTANCE)} /> : <></>}

            {isShowAuditPending ? <VehicleAuditPending orderId={orderData?.orderId} /> : <></>}
            {/* {serviceType && !HM_CAN_DISPATCH_SERVICE_TYPE.includes(extractValue("serviceType")) && (
              <Notifications message={` ${serviceType} service type, Only the central dispatch team is authorized to dispatch`} query={serviceType} />
            )} */}
            <Wrap w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
              <Box w={"full"}>
                <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"} px={4}>
                  Dispatch Details
                </Heading>
                <Divider my={2} />
              </Box>
              <Wrap px={4} mt={-6}>
                <WrapItem maxW={"full"} mb={4}>
                  {extractValue("waypoints[0].sourceId") ? (
                    <TextRenderWithIcon
                      icon="material-symbols:location-on-outline"
                      title="Pickup Point"
                      description={sourceMap[extractValue("waypoints[0].sourceId")]?.name}
                      subTitle={TextMaker(extractValue("waypoints[0]"))}
                    />
                  ) : (
                    <TextRenderWithIcon icon="material-symbols:location-on-outline" title="Pickup Point" description={TextMaker(extractValue("waypoints[0]"))} />
                  )}
                </WrapItem>
                <WrapItem maxW={"full"} mb={4}>
                  {extractValue("waypoints[1].sourceId") ? (
                    <TextRenderWithIcon
                      icon="mingcute:navigation-line"
                      title="Drop"
                      description={sourceMap[extractValue("waypoints[1].sourceId")]?.name}
                      subTitle={TextMaker(extractValue("waypoints[1]"))}
                    />
                  ) : (
                    <TextRenderWithIcon icon="mingcute:navigation-line" title="Drop" description={TextMaker(extractValue("waypoints[1]"))} />
                  )}
                </WrapItem>
              </Wrap>
              <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(1, 1fr)" w={"full"} gap={10} px={4}>
                {extractValue("scheduledDdtm") && (
                  <GridItem flex={1} colSpan={2}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                      <TextRenderWithIcon
                      icon={"mdi:clock-time-three-outline"}
                      title={"Scheduled At"}
                      render={() => editScheduledTime ? <DatePicker minDate={new Date()} wrapperClassName="datepicker"  minTime={isToday ? minDate : new Date(currentDate.setHours(0, 0, 0))} maxTime={new Date(currentDate.setHours(23, 59, 59))} onChange={(val:any) => {setNewScheduledTime(new Date(val).getTime()); setNewScheduledTimeEdited(true)}} selected={newScheduledTime || extractValue("scheduledDdtm")}  showTimeSelect dateFormat="d MMM yyyy h:mm aa"  timeIntervals={1} /> : <Text fontSize={"sm"} fontWeight={"semibold"} textTransform={"capitalize"} color="blackAlpha.900">{dayjs.unix((newScheduledTime || extractValue("scheduledDdtm") || 1000) / 1000).format("DD MMM YYYY hh:mm A")}</Text>}
                      /> 
                      {!editScheduledTime && <Button
                        onClick={() => setEditScheduledTime(true)}
                        variant={"ghost"}
                        colorScheme="primary"
                        size={"xs"}
                        borderRadius={"full"}
                        fontWeight={"semibold"}
                        px={4}
                        mr={0}
                        leftIcon={<Icon fontSize={16} icon="line-md:edit-twotone" />}
                        _focus={{ borderWidth: 0 }}
                      >
                        Edit
                      </Button>}
                    </Flex>
                  </GridItem>
                )}
              </Grid>
              {editScheduledTime && <Wrap w={'full'} px={4} mt={-4}>
                <WrapItem w={'full'} maxW={"full"}>
                <Flex flexDirection={'row'} justifyContent={'space-between'}  w={'full'} alignItems={'flex-end'}>
                  <TextRenderWithIcon
                    icon={"mdi:note-alert-outline"}
                    title={"Scheduled edit reason"}
                    render={() => <Input className="scheduled-edit-reason" disabled={!newScheduledTimeEdited} maxW={'100%'} h={8} value={reasonForChangeScheduledTime} onChange={(e) => setReasonForChangeScheduledTime(e.target.value)}/>}
                  /> 
                  <Flex flexDirection={'row'} w={20}>
                    {isLoading ? <Box w={10}><Loader thickness={'1px'} size={'sm'} /></Box>
                    :
                    <>
                    <Button
                    onClick={() => {setEditScheduledTime(false); setNewScheduledTimeEdited(false); setNewScheduledTime(extractValue("scheduledDdtm"))}}
                    variant={"ghost"}
                    colorScheme="red"
                    px={0}
                    pl={2}
                    size={"md"}
                    borderRadius={"full"}
                    fontWeight={"semibold"}
                    leftIcon={<Icon fontSize={16} icon="line-md:cancel" />}
                    _focus={{ borderWidth: 0 }}
                    >
                    </Button>
                    <Button
                    disabled={!newScheduledTimeEdited || (((new Date(newScheduledTime).getTime() - new Date().getTime())) <= (45 * 60 * 1000))}
                    onClick={(((new Date(newScheduledTime).getTime() - new Date().getTime())) <= (45 * 60 * 1000)) ? () => {} : updateScheduledDate}
                    variant={"ghost"}
                    colorScheme={(((new Date(newScheduledTime).getTime() - new Date().getTime())) <= (45 * 60 * 1000)) ? "gray" : "primary"}
                    size={"md"}
                    px={0}
                    pl={2}
                    borderRadius={"full"}
                    fontWeight={"semibold"}
                    leftIcon={<Icon fontSize={16} icon="line-md:confirm" />}
                    _focus={{ borderWidth: 0 }}
                    >
                    </Button>
                      </>
                    }
                  </Flex>
                </Flex>
                </WrapItem>
                {(((new Date(newScheduledTime).getTime() - new Date().getTime())) <= (45 * 60 * 1000)) && <Text color="red" size="xs" ml="10">Schedule time is invalid</Text>}
              </Wrap>}

              <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" w={"full"} gap={10} px={4}>

                <WrapItem maxW={200}>
                  <TextRenderWithIcon icon={"ph:taxi-bold"} title={"Ambulance Type"} description={ambulanceMapper[extractValue("entityRequired[0].subtype")]} />
                </WrapItem>
                <WrapItem maxW={200}>
                  <TextRenderWithIcon icon={"mdi:clock-time-three-outline"} title={"Dispatch"} description={extractValue("dispatchType") === "NOW" ? "Immediate" : "Scheduled"} />
                </WrapItem>

                <WrapItem maxW={200}>
                  <TextRenderWithIcon
                    icon={"game-icons:path-distance"}
                    title={"Distance"}
                    description={`${Number(extractValue("distance")) / 1000 > 1 ? (Number(extractValue("distance")) / 1000).toFixed(1) : (Number(extractValue("distance")) / 1000).toFixed(3)} Km`}
                  />
                </WrapItem>
                <WrapItem maxW={200}>
                  <TextRenderWithIcon
                    icon={"tabler:external-link"}
                    title={"Tracking Link"}
                    render={() =>
                      extractValue("assignmentDetails.trackingLink") ? (
                        <Link to={extractValue("assignmentDetails.trackingLink")} target="_blank">
                          <Flex alignItems={"center"}>
                            <Text fontSize={"sm"} fontWeight={"semibold"} color={"blue"}>
                              Link
                            </Text>{" "}
                            <ExternalLinkIcon mx="2px" fontSize={"sm"} color={"blue"} />
                          </Flex>
                        </Link>
                      ) : (
                        <Text>-</Text>
                      )
                    }
                  />
                </WrapItem>

                <WrapItem maxW={200}>
                  <TextRenderWithIcon
                    icon={"ep:platform"}
                    title={"Platform"}
                    description={`${extractValue("originSource.platform")} ${extractValue("originSource.version") && `(${extractValue("originSource.version")})`}`}
                  />
                </WrapItem>

                <WrapItem maxW={200}>
                  <TextRenderWithIcon icon={"mdi:source-fork"} title={"Source Name"} description={sourceMap[extractValue("originSource.source")]?.name || "-"} />
                </WrapItem>
              </Grid>
              <Wrap px={4}>
                <WrapItem maxW={"full"}>
                  <TextRenderWithIcon icon={"ep:user"} title={"Generated By"} description={extractValue("generatedBy")} />
                </WrapItem>
              </Wrap>
            </Wrap>

            <PatientSection />

            <Wrap w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
              <Box w={"full"} mb={"-6"}>
                <Flex justify={"space-between"} align={"center"} px={4}>
                  <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
                    Patient info
                  </Heading>
                  {/* {orderData.billToClient && <IpPatientNumber isDisabled={false} />} */}
                </Flex>
                <Divider my={2} />
              </Box>
              <Wrap px={4}>
                <TextRenderWithIcon icon="uil:heart-medical" title={"Medical Condition"} description={(extractValue("requestedFor.medicalIssue.reason") || []).join(", ")} />
              </Wrap>
              <SimpleGrid w={"full"} columns={2} gap={8} px={4}>
                <TextRenderWithIcon icon={"mdi:weight-lifter"} title={"Weight"} description={`${extractValue("requestedFor.weight") || "0"} Kg`} />
                <TextRenderWithIcon
                  icon={"game-icons:body-height"}
                  title={"Height"}
                  description={`${extractValue("requestedFor.height.feet") || 0} ft ${extractValue("requestedFor.height.inches") || 0} inch`}
                />
                <TextRenderWithIcon icon="bi:gender-trans" title={"Gender"} description={extractValue("requestedFor.gender") || "Unknown"} />
                <TextRenderWithIcon icon="noto:mage" title={"Age"} description={`${extractValue("requestedFor.age") || "Unknown"} Years`} />
                {orderData.billToClient && (
                  <Box pos={"relative"}>
                    <TextRenderWithIcon icon="mdi:ip-outline" title={"IP Patient Number"} description={`${extractValue("ipPatientNumber") || "-"}`} />
                    <Box ml={10}>
                      <IpPatientNumber isDisabled={false} />
                    </Box>
                  </Box>
                )}
              </SimpleGrid>
            </Wrap>

            <Wrap w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
              <Box w={"full"} mb={"-6"}>
                <Flex justify={"space-between"} align={"center"} px={4}>
                  <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
                    Ambulance Details
                  </Heading>
                  {!!auditData && auditData?.length > 0 && <ViewAuditData auditData={auditData} />}
                </Flex>
                <Divider my={2} />
              </Box>
              <SimpleGrid w={"full"} columns={2} gap={8} px={4}>
                <WrapItem maxW={["full", 200]}>
                  <TextRenderWithIcon
                    icon="ph:lifebuoy"
                    title={"Pilot Name"}
                    render={() => (
                      <Box>
                        <Text fontSize={"xs"} fontWeight={"semibold"}>
                          {extractValue("assignmentDetails.pilotDetails.meta.userName") || "-"}
                        </Text>
                        <Text fontSize={"xs"} fontWeight={"semibold"}>
                          {extractValue("assignmentDetails.pilotDetails.meta.mobile") || "-"}
                        </Text>
                      </Box>
                    )}
                  />
                </WrapItem>
                <TextRenderWithIcon icon={"ph:taxi-bold"} title={"Vehicle Number"} description={`${extractValue("assignmentDetails.fleetDetails.ambNumberPlate") || "-"}`} />{" "}
                {/* <TextRenderWithIcon icon={"ph:office-chair-bold"} title={"Ownership"} description={extractValue("vehicleNumber") || "-"} />{" "} */}
                <TextRenderWithIcon icon="mdi:identification-card-outline" title={"Vehicle"} description={ambulanceMapper[extractValue("entityRequired[0].subtype")] || "-"} />
                <TextRenderWithIcon
                  icon="ph:first-aid-kit"
                  title={"Paramedic Name"}
                  render={() => (
                    <Box>
                      <Text fontSize={"xs"} fontWeight={"semibold"}>
                        {extractValue("assignmentDetails.paramedicDetails.meta.userName") || "-"}
                      </Text>
                      <Text fontSize={"xs"} fontWeight={"semibold"}>
                        {extractValue("assignmentDetails.paramedicDetails.meta.mobile") || "-"}
                      </Text>
                    </Box>
                  )}
                />{" "}
                <TextRenderWithIcon
                  icon="ph:arrows-merge-bold"
                  title={"Dedicated/ Independent"}
                  description={
                    !isInvalidString(extractValue("assignmentDetails.fleetDetails.isDedicated"))
                      ? extractValue("assignmentDetails.fleetDetails.isDedicated")
                        ? "Dedicated"
                        : "Independent"
                      : "Unknown"
                  }
                />{" "}
              </SimpleGrid>
            </Wrap>
            <Stack w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
              <Box w={"full"} mb={"-6"}>
                <Flex justify={"space-between"} align={"center"} px={4}>
                  <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
                    Payments
                  </Heading>
                  {!extractValue("billToClient") && (
                    <Button
                      isLoading={paymentResponse?.isLoading}
                      onClick={onOpen}
                      variant={"ghost"}
                      colorScheme="primary"
                      size={"xs"}
                      borderRadius={"full"}
                      fontWeight={"semibold"}
                      px={4}
                      pr={0}
                      mr={0}
                      leftIcon={<Icon fontSize={16} icon="tabler:transaction-rupee" />}
                      _focus={{ borderWidth: 0 }}
                    >
                      View Transactions
                    </Button>
                  )}
                </Flex>
                <Divider my={2} />
              </Box>
              <Wrap px={4}>
                <WrapItem maxW={["full", 200]}>
                  <TextRenderWithIcon
                    icon={"mingcute:bill-fill"}
                    title={"Bill To"}
                    render={() => {
                      return extractValue("billToClient") ? (
                        <Flex align={"center"}>
                          <Icon icon={"flat-color-icons:ok"} />{" "}
                          <Text ml={2} fontSize={"xs"} fontWeight={"semibold"}>
                            Bill to Hospital
                          </Text>
                        </Flex>
                      ) : (
                        <Flex align={"center"}>
                          <Icon icon={"flat-color-icons:ok"} />{" "}
                          <Text ml={2} fontSize={"xs"} fontWeight={"semibold"}>
                            Bill to Patient
                          </Text>
                        </Flex>
                      );
                    }}
                  />
                </WrapItem>
              </Wrap>
              {extractValue("overridenComments") && (
                <Wrap px={4}>
                  <TextRenderWithIcon icon="basil:comment-solid" title="Overridden Comments" description={extractValue("overridenComments")?.toString().replace(/_/g, " ")} />
                </Wrap>
              )}
              <SimpleGrid w={"full"} columns={2} gap={8} px={4}>
                <TextRenderWithIcon icon="tabler:sum" title={"Total Amount Payable"} description={`₹ ${convertPrice(parseInt(extractValue("totalFare") || "0")).toString()}`} />

                <TextRenderWithIcon
                  icon="ic:round-paid"
                  title={"Amount Paid"}
                  description={`₹ ${convertPrice((extractValueFromPayment("totalAmount") && extractValueFromPayment("totalAmount") - extractValueFromPayment("amountPending")) || 0)}`}
                />
                <TextRenderWithIcon icon="solar:bill-bold-duotone" title={"Amount to be paid"} description={`₹ ${convertPrice(extractValueFromPayment("amountPending") || "0")}`} />
                <TextRenderWithIcon icon="bxs:ambulance" title={"Ambulance Fare"} description={`₹ ${convertPrice(parseInt(extractValue("fareAgreed") || "0")).toString()}`} />
                <TextRenderWithIcon icon="icon-park-solid:add-one" title={"Add-Ons Fare"} description={`₹ ${convertPrice(parseInt(extractValue("addonsFare") || "0")).toString()}`} />

                <TextRenderWithIcon
                  icon="iconamoon:invoice-duotone"
                  title={"Invoice"}
                  render={() => (
                    <Box>
                      {extractValue("invoiceUrl") ? (
                        <Tag size={"md"} variant="subtle" colorScheme="primary" mt={1} onClick={() => window?.open(extractValue("invoiceUrl"), "_blank")}>
                          <TagLeftIcon boxSize="14px" as={Download} />
                          <TagLabel fontSize={"xs"} fontWeight={"semibold"} ml={1}>
                            Download
                          </TagLabel>
                        </Tag>
                      ) : (
                        "-"
                      )}
                    </Box>
                  )}
                />
              </SimpleGrid>
              <Wrap p={4} mt={-6}>
                <TextRenderWithIcon icon="mdi:counter" title={"Add-Ons (Quantity)"} description={getAddonsName(extractValue("addons") || [])} />
              </Wrap>
            </Stack>

            {/* <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
              <Box w={"full"} mb={"-6"}>
                <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
                  Margins & Discounts
                </Heading>
                <Divider my={2} />
              </Box>
              <SimpleGrid columns={2} w={"full"}>
                <TextRenderWithIcon icon="mdi:margin" title="Margin" description={extractValue("stanplusMargin")} />
                <TextRenderWithIcon icon="iconamoon:discount-fill" title="Discount" description={extractValue("stanplusMargin")} />
              </SimpleGrid>
            </Wrap> */}

            {extractValue("feedback.date") && <Feedback />}

            <UploadDocuments />

            {extractValue("billToClient") && <UploadBTHDocuments />}
          </Box>
        </Flex>
        <Box>
          <ActonSection />
        </Box>

        <Center gap={4} flexWrap={"wrap"} my={10}>
          <DocumentUploadLink />
          <CancelOrder />
        </Center>
      </Stack>
      <IconButton
        pos={"fixed"}
        right={14}
        bottom={16}
        bg="red.100"
        aria-label="Search database"
        color={"red"}
        icon={<Icon icon={"subway:add"} fontSize={40} />}
        borderRadius={"full"}
        size={"2xl"}
        p={2}
        onClick={() => (window.location.pathname = "/create-case")}
        className="blob"
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={3}>
          <ModalHeader fontSize={"sm"}>Case Transaction Log</ModalHeader>
          <Divider mt={-2} />
          <ModalCloseButton />
          <ModalBody>{orderData && <TransactionComponent />}</ModalBody>
        </ModalContent>
      </Modal>
    </NetworkWrapper>
  );
}

const Notifications = ({ message, query }: { message: string; query: string }) => {
  const darkSlate = useColorModeValue("white", "slate.800");
  return (
    <Wrap w={"full"} bg={darkSlate} px={6} py={4} my={6} spacing={10} borderRadius={"md"}>
      <Flex justify={"center"} align={"center"} gap={2}>
        <Box bg={"red.100"} borderRadius={"full"}>
          <Box m={2}>
            <Icon icon={"ic:round-info"} fontSize={24} color="red" />
          </Box>
        </Box>
        <Heading fontSize={"sm"} color={"red.500"}>
          <Highlight query={query} styles={{ px: "2", py: "1", rounded: "full", bg: "orange.100" }}>
            {message}
          </Highlight>
        </Heading>
        {/* <Text fontSize={"md"} fontWeight={"normal"} textTransform={"capitalize"}> */}

        {/* </Text> */}
      </Flex>
    </Wrap>
  );
};

const ActonSection = () => {
  const onSelect = () => {};

  return <Box>{/* <ActionButton label="Collect Advance" onClick={onSelect} /> */}</Box>;
};

const PendingPaymentCard = ({ amount, orderId }: { amount: number; orderId: string }) => {
  const darkSlate = useColorModeValue("white", "slate.800");
  const navigate = useNavigate();

  const params = {
    onSuccessNavigate: `/case-history/${orderId}/case-overview`,
  };

  const handleOnPayClick = () => {
    navigate(`/case/${orderId}/payment?${objToQuery(params)}`);
  };

  return (
    <Wrap w={"full"} bg={darkSlate} px={4} py={4} mt={6} spacing={10} borderRadius={"md"}>
      <Flex w={"full"} justify={"space-between"} align={"center"}>
        <Flex w={"full"} align={"center"}>
          <Box padding={0} bg={"red.100"} borderRadius={"full"} className="blob" color="red">
            <Icon icon={"ph:currency-inr-fill"} fontSize={32} />
          </Box>
          <Heading fontWeight={"normal"} fontSize={"sm"} color={"gray.700"} ml={5}>
            Outstanding Fare <br />
            <span style={{ fontWeight: 800, color: "red" }}>{convertToCurrency(amount)}</span>
          </Heading>
        </Flex>
        <Button size={"sm"} px={5} borderRadius={"full"} bg={"red.100"} color={"red.600"} fontWeight={"semibold"} borderWidth={2} borderColor={"red.200"} onClick={handleOnPayClick}>
          Pay Now
        </Button>
      </Flex>
    </Wrap>
  );
};

const VehicleAuditPending = ({ orderId }: { orderId: string }) => {
  const darkSlate = useColorModeValue("white", "slate.800");
  const navigate = useNavigate();

  const handleOnPayClick = () => {
    navigate(`/case/${orderId}/vehicle-audit `);
  };

  return (
    <Wrap w={"full"} bg={darkSlate} px={4} py={4} mt={6} spacing={10} borderRadius={"md"}>
      <Flex w={"full"} justify={"space-between"} align={"center"}>
        <Flex w={"full"} align={"center"}>
          <Box padding={0} borderRadius={"full"}>
            <Icon icon={"fluent-emoji:ambulance"} fontSize={32} />
          </Box>
          <Heading fontWeight={"normal"} fontSize={"sm"} color={"gray.700"} ml={5}>
            Vehicle Audit is Pending <br />
          </Heading>
        </Flex>
        <Button size={"sm"} px={5} borderRadius={"full"} bg={"blue.100"} color={"blue.600"} fontWeight={"semibold"} borderWidth={2} borderColor={"blue.200"} onClick={handleOnPayClick}>
          Audit Now
        </Button>
      </Flex>
    </Wrap>
  );
};

const CaseOnHold = () => {
  const darkSlate = useColorModeValue("white", "slate.800");
  const navigate = useNavigate();
  return (
    <Wrap w={"full"} bg={darkSlate} px={4} py={4} mt={6} spacing={10} borderRadius={"md"}>
      <OnHold isLiteMode={true} />
    </Wrap>
  );
};

const Download = () => <Icon icon={"ic:round-file-download"} fontSize={14} />;
